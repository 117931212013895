<template>
  <div class="content-wrapper overflow-visible">
    <patient-info :patient-data="patientData" :loading="loading.patientInfo" :use-history-data="true" :reg-id="$route.params.pageSlug" />

    <div class="content">
      <template v-if="isListDetail">
        <div class="page-title pt-0 pb-3">
          <a href="javascript:;" @click="back()" class="text-dark">
            <i class="icon-arrow-left52 mr-1"></i>
            <span class="font-weight-semibold">Kembali</span>
          </a>
        </div>
        
        <div class="row g-2">
          <div class="col-md-12">
            <div class="card">
              <div class="card-header">
                <div class="d-flex align-items-center">
                  <h5 class="card-title font-weight-semibold mr-auto">Monitoring Kajian Restrain</h5>
                  <a href="javascript:;" v-if="moduleRole('add')" @click="addKajian" class="btn bg-blue-600 btn-labeled btn-labeled-left">
                    <b><i class="icon-plus2"></i></b>Buat Kajian Restrain
                  </a>
                </div>
              </div>
              <div class="card-body">
                <table class="table table-bordered table-sm patient-table">
                  <thead>
                    <tr>
                      <th>No.</th>
                      <th>Tanggal/Jam</th>
                      <th>Perawat</th>
                      <th>Status</th>
                      <th style="width:15%">Aksi</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(v,k) in (dataKajian||[])" :key="k" 
                    :class="v.arankpkr_is_parent == 'Y' ? 'table-info' : ''">
                      <td>
                        <span class="font-weight-semibold" v-b-tooltip.hover title="Lihat Detail">{{k+1}}</span>
                      </td>
                      <td>{{v.arankpkr_date | moment('DD MMMM YYYY')}}, {{v.arankpkr_jam}}
                        <span v-if="v.arankpkr_is_parent == 'Y'" class="badge bg-success ml-2">Assesmen Awal </span>
                        <span v-if="v.arankpkr_is_parent != 'Y'" class="badge bg-info ml-2">Monitoring </span>  
                        <p class="mt-1" style="font-size: 11px;" v-if="v.arankpkr_updated_date"> <b>Terakhir Diupdate pada : </b> <br/>{{v.arankpkr_updated_date | moment('DD MMMM YYYY HH:mm:ss')}} </p>                    
                      </td>
                      <td>{{v.name_petugas||"-"}}</td>
                      <td>
                        <span v-if="v.arankpkr_is_draft == 'Y' && !v.arankpkr_status" class="badge bg-warning">Draft </span>
                        <template v-else>
                          <span v-if="v.arankpkr_status == 'DONE'" class="badge bg-success">Selesai Monitoring</span>
                          <span v-else class="badge bg-info">Dalam Monitoring </span>
                        </template>                      
                      </td>
                      <td>
                        <a href="javascript:;" @click="toModal(v,'xl',(v.arankpkr_is_parent=='Y'?'Detail Kajian Restrain':'Detail Kajian Restrain Monitoring'),(v.arankpkr_is_parent=='Y'?'DetailKajianRestrain':'DetailKajianRestrainMonitoring'))" v-if="moduleRole('view') && v.arankpkr_is_draft != 'Y'"
                          class="btn btn-icon ml-1 mb-1 alpha-blue border-blue" v-b-tooltip.hover title="Lihat Kajian Restrain"><i class="icon-file-eye"></i></a>

                        <template>
                          <a href="javascript:;" @click="toForm(v)" v-if="!v.arankpkr_created_by" class="btn btn-icon alpha-success border-success ml-1 mb-1" v-b-tooltip.hover title="Tambah Monitoring"><i class="icon-plus2"></i></a>
                          <a href="javascript:;" @click="toForm(v)" v-else
                            class="btn btn-icon ml-1 mb-1 alpha-info border-info" v-b-tooltip.hover title="Edit"><i class="icon-pencil4"></i></a>
                        </template>
                        
                        <a href="javascript:;" @click="hapusTindakan(v)" v-if="moduleRole('delete') && v.arankpkr_is_draft == 'Y' && v.arankpkr_is_parent == 'Y'"
                          class="btn btn-icon ml-1 mb-1 alpha-danger border-danger" v-b-tooltip.hover title="Hapus"><i class="icon-trash"></i></a>
                      </td>
                    </tr>
                    <tr v-if="!(dataKajian||[]).length">
                      <td colspan="99" class="text-center">Data Tidak ada</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        
        <b-modal
          v-model="openModal"
          :size="modalConfig.size"
          no-close-on-backdrop
          hide-footer
          :title="modalConfig.title" 
        >
          <DetailKajianRestrain v-if="isModal == 'DetailKajianRestrain'" />     
          <DetailKajianRestrainMonitoring v-if="isModal == 'DetailKajianRestrainMonitoring'" />
        </b-modal>

      </template>
      <template v-else>
        <FormMonitoring v-if="typeForm == 'monitoring'" /> 
        <Form v-else />
      </template>
    </div>
  </div>
</template>


<script>
  import GlobalVue from '@/libs/Global.vue'
  import $ from 'jquery'
  const _ = global._
  import Gen from '@/libs/Gen.js'
  import Form from './Form.vue'
  import FormMonitoring from './FormMonitoring.vue'
  import DetailKajianRestrain from './DetailKajianRestrain.vue'
  import DetailKajianRestrainMonitoring from './DetailKajianRestrainMonitoring.vue'
  import PatientInfo from '@/components/Ranap/PatientInfo.vue'

  
  export default {
    extends: GlobalVue,
    components:{Form,FormMonitoring,DetailKajianRestrain,DetailKajianRestrainMonitoring,PatientInfo},
    data() {
      return {
        rowReg: {},
        dataKajian: [],
        
        modalConfig: {
          title: '',
          size: 'sm'
        },
        isModal: '',
        rowEdit: {},
        openModal: false,

        patientData: {},
        loading: {
            patientInfo: false,
        }
      }
    },
    computed: {
      isListDetail(){ return !this.$route.params.kajianNo },  
      typeForm(){ return this.$route.query.typeForm },  
    },
    methods: {
      back(){
        this.$router.push({name : 'RanapPasienPerawatan'}).catch({})
      },
      toModal(row,size,title,isModal){
        this.rowEdit = row
        this.modalConfig = {
          title: title,
          size: size
        }
        this.isModal = isModal
        this.openModal = true
      },
      
      getPatientInfo() {
        this.$set(this.loading, 'patientInfo', true)
        Gen.apiRest(
        "/do/" + 'RanapPasienPerawatan', {
            data: {
              type: "get-data-pasien",
              id_registrasi: this.$route.params.pageSlug
            }
        },
        "POST"
        ).then(resp => {
        this.$set(this, 'patientData', resp.data.data)

        this.$set(this.loading, 'patientInfo', false)
        })
      },

      apiGet(params = {}, page = 1) {
        if (!this.pageSlug) {
          this.$router.push({
            name: 'Dashboard'
          }).catch(() => {})
        }

        if (this.pageSlug || this.$route.name == 'Dashboard') this.loadingOverlay = true
        this.data.data = false
        let paramsQuery = Object.keys(this.$route.query).length ? this.apiParams : this.$route.query
        let url = this.modulePage + '/' + this.$route.params.pageSlug

        Gen.apiRest(
          "/get/" + url, {
            params: Object.assign({
              page: page
            }, paramsQuery, params.query || {})
          }
        ).then(res => {
          this.loadingOverlay = false
          _.forEach(res.data, (v, k) => {
            this.$set(this, k, v)
          })
          if (!this.isFound) {
            this.$router.push({
              name: 'Dashboard'
            }).catch(() => {})
          }
        }).catch(() => {
          this.loadingOverlay = false
        })
      },
      hapusTindakan(row){
        this.$swal({
          icon: 'warning',
          title: 'Hapus Kajian Restrain?',
          showCancelButton: true,
          confirmButtonText: 'Ya',
          cancelButtonText: 'Tidak, kembali'

        }).then(result => {
          if(result.value){
            this.loadingOverlay = true
            let data = {
              id : row.arankpkr_id
            }
            data.type = 'hapus-kajian'
            Gen.apiRest(
                "/do/"+this.modulePage,
                {data: data}, 
                "POST"
            ).then(res=>{
              this.loadingOverlay = false
              this.$swal({
                title: "Kajian Restrain Berhasil DiHapus",
                icon: 'success',
                confirmButtonText: 'Ok'
              })
              this.apiGet()
            })
          }
        })
      },
      addKajian(){
        this.loadingOverlay = true
        let data = {
          regId : this.rowReg.aranr_id
        }
        data.type = 'init-kajian'
        Gen.apiRest(
            "/do/"+this.modulePage,
            {data: data}, 
            "POST"
        ).then(res=>{
            let resp = res.data
            this.loadingOverlay = false
            this.$router.push({ name: this.modulePage, params: {pageSlug: this.rowReg.aranr_id, kajianNo: resp.arankpkr_id}})
            .catch(()=>{})
        })
      },
      toForm(row,form=''){
        if(form){
          this.$router.push({ 
          name: this.modulePage, 
          params: {pageSlug: this.rowReg.aranr_id, kajianNo: row.arankpkr_id},
          query: {form: form}
          })
          .catch(()=>{})
        }else{
          if(row.arankpkr_is_parent !='Y'){
            this.$router.push({ name: this.modulePage, params: {pageSlug: this.rowReg.aranr_id, kajianNo: row.arankpkr_id},
            query:{typeForm:'monitoring'}})
            .catch(()=>{})          
          }else{  
            this.$router.push({ name: this.modulePage, params: {pageSlug: this.rowReg.aranr_id, kajianNo: row.arankpkr_id}})
            .catch(()=>{})            
          }
        }
      }
    },
    mounted() {
      this.apiGet()
      this.getPatientInfo()
    },
    watch:{
      $route(){
        this.apiGet()
      }
    }
  }
</script>